import i18next from 'i18next'

let languages = document.querySelectorAll('.languages ul li')
const updateContent = () => {
    const content = document.querySelectorAll('[data-i18n]')
    content.forEach((element) => {
        const key = element.dataset.i18n
        element.innerHTML = i18next.t(key)
    })

    languages.forEach((language) => {
        const lng = language.dataset.lng
        if (lng === i18next.language) {
            language.classList.add('active')
        } else {
            language.classList.remove('active')
        }
    })

    document.querySelector('#ctaLinkGda').href = `${process.env.LINK_GDA}?lang=${i18next.language}`
    document.querySelector('#ctaLinkWwa').href = `${process.env.LINK_WWA}?lang=${i18next.language}`
}
i18next.init(
    {
        lng: 'pl',
        fallbackLng: 'pl',
        resources: {
            pl: {
                translation: {
                    header: 'Wybierz lokalizacje',
                    openingSoon: 'Wkrótce otwarcie',
                    more: 'więcej na',
                    enter: 'Wejdź',
                },
            },
            en: {
                translation: {
                    header: 'Select a location',
                    openingSoon: 'Opening soon',
                    more: 'more on',
                    enter: 'Enter',
                },
            },
        },
    },
    updateContent
)
i18next.on('languageChanged', updateContent)
languages.forEach((language) => {
    const lng = language.dataset.lng
    language.addEventListener('click', () => {
        i18next.changeLanguage(lng)
    })
})

let sections = document.querySelectorAll('.section')
sections.forEach((section) => {
    let ctaButton = section.querySelector('.cta-button')
    ctaButton.addEventListener('click', (event) => {
        event.stopPropagation()
        section.classList.add('active')
    })
})
document.addEventListener('click', () => {
    sections.forEach((section) => {
        section.classList.remove('active')
    })
})
